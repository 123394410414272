import "./Projects.css";
import { Container } from "react-bootstrap";
import Project from "./Project/TeachMe";
import DidYouRSVP from "./Project/DidYouRSVP";
import ConGo from "./Project/ConGo";
import FitCheck from "./Project/FitCheck";

const Projects = () => {
  return (
    <Container className="project-container">
      <h1>
        <samp>SOFTWARE PROJECTS</samp>
      </h1>
      <section className="projects">
        <div className="project-holder">
          <FitCheck />
        </div>
        <div className="project-description">
          <samp>
            <h3 className="project-title">FitCheck.one</h3>
            <h5>Outfit Feedback Application</h5>
            Tools Used: React, JavaScript, Python, FastAPI, Redux, Docker,
            Postgres, HTML, CSS, Spline, Git, CapRover
            <ul>
              <li>
                Led team of 4 software engineers to ensure development of highly
                efficient and robust code from wireframe to deployment. Enabling
                users to upload outfit pictures and receive valuable feedback
                for improved fashion choices.
              </li>
              <li>
                Implemented responsive front-end using React, JavaScript, HTML,
                CSS, Redux, and Spline, creating an intuitive and visually
                appealing interface that fostered seamless user interactions
              </li>
              <li>
                Orchestrated robust back-end infrastructure using FastAPI,
                Python, PostgreSQL, and Docker, ensuring scalability and
                efficient management of high user traffic and data storage
              </li>
            </ul>
            <a
              type="button"
              className="btn btn-success"
              href="https://www.linkedin.com/posts/amro-salha_new-software-alert-deployed-at-https-activity-7062160908709482497-T4x7?utm_source=share&utm_medium=member_desktop"
              target="_blank"
              rel="noreferrer"
            >
              DEMO VIDEO
            </a>
            <a
              type="button"
              className="btn btn-info"
              href="https://fitcheck.one"
              target="_blank"
              rel="noreferrer"
            >
              WEBSITE
            </a>
          </samp>
        </div>

        <div className="project-holder">
          <Project />
        </div>
        <div className="project-description">
          <samp>
            <h3 className="project-title">TeachMe.ai</h3>
            <h5>Learning Tool Software</h5>
            Tools Used: Javascript, React, Django, Python, HTML, CSS,
            PostgreSQL, Redux, Axios
            <ul>
              <li>
                Full-stack designed and engineered ‘TeachMe.ai’, a web app with
                three AI-powered bots for personalized education
              </li>
              <li>
                Utilized JavaScript, React, Redux, Django, and PostgreSQL to
                build the front-end, back-end, and self-built API for managing
                user-generated curriculum histories.
              </li>
              <li>
                Leveraged the OpenAI API to power the TeachMe.ai bots, resulting
                in an optimized learning experience for users.
              </li>
            </ul>
            <a
              type="button"
              className="btn btn-success"
              href="https://www.linkedin.com/feed/update/urn:li:activity:7044100958762520576/"
              target="_blank"
              rel="noreferrer"
            >
              DEMO VIDEO
            </a>
          </samp>
        </div>

        <div>
          <DidYouRSVP />
        </div>
        <div className="project-description">
          <samp>
            <h3 className="project-title">DidYouRSVP.com</h3>
            <h5>Event database and invitation creation application</h5>
            Tools Used: Django 4, Python, CSS, HTML, SQL
            <ul>
              <li>
                Maintained a simple, impressive, front-end design with CSS,
                providing an easy-to-use user interface
              </li>
              <li>
                Using Django and Python; developed event models, guest forms, a
                secure login page, and restricted access pages for users and
                those visiting the site as guests
              </li>
              <li>
                Built a monolith application following domain-driven design with
                Django, Python, HTML, and CSS
              </li>
            </ul>
            <a
              type="button"
              className="btn btn-success"
              href="https://www.linkedin.com/feed/update/urn:li:activity:7027413320861147136/"
              target="_blank"
              rel="noreferrer"
            >
              DEMO VIDEO
            </a>
          </samp>
        </div>

        <div>
          <ConGo />
        </div>
        <div className="project-description">
          <samp>
            <h3 className="project-title">ConferenceGo.com</h3>
            <h5>An Event Tracking Application </h5>
            Tools Used: Django 4, CSS, HTML, Javascript ES6+, ReactJS, React
            Hooks, Docker, RabbitMQ, MailHog
            <ul>
              <li>
                Utilized API resourcing, integrated location, and weather API
                endpoints, to bring more visual detail to event data
              </li>
              <li>
                Implemented automatic SMTP via pub/sub with MailHog to automate
                status change emails being sent to presenters
              </li>
              <li>
                Established advanced message queuing protocol between
                microservices with RabbitMQ to distribute data efficiently
              </li>
              <li>
                Restructured a large, monolithic Django application into a
                scalable, microservices-based architecture. Tested division
                through the use of Docker containers
              </li>
            </ul>
            <a
              type="button"
              className="btn btn-warning"
              href="https://gitlab.com/amro.salha/fearless-frontend"
              target="_blank"
              rel="noreferrer"
            >
              GITLAB REPO
            </a>
          </samp>
        </div>
      </section>
    </Container>
  );
};
export default Projects;
