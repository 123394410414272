import { useState } from "react"
import { Container, Button } from "react-bootstrap"
import './NavBox.css'
import Projects from "../Projects/Projects"
import WorkExperience from "../WorkExperience/WorkExperience"
import Education from "../Education/Education"
import ContactMe from "../ContactMe/ContactMe"

const NavBox = () => {
    const [projectsClicked, setProjectsClicked] = useState(false)
    const [workExperienceClicked, setWorkExperienceClicked] = useState(false)
    const [educationClicked, setEducationClicked] = useState(false)
    const [contactMeClicked, setContactMeClicked] = useState(false)

    // {projectsClicked ? <Projects /> : '' }
    // onClick={() => projectsClicked ? setProjectsClicked(false) : setProjectsClicked(true)}



    return (
    <>
        <Container className='nav-container'>
            <section className="nav">

                    <button onClick={() => projectsClicked ? setProjectsClicked(false) : (setProjectsClicked(true), setEducationClicked(false), setContactMeClicked(false), setWorkExperienceClicked(false))} className="section" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vw" height="4vh" fill="currentColor" className="bi bi-hammer" viewBox="0 0 16 16">
                        <path d="M9.972 2.508a.5.5 0 0 0-.16-.556l-.178-.129a5.009 5.009 0 0 0-2.076-.783C6.215.862 4.504 1.229 2.84 3.133H1.786a.5.5 0 0 0-.354.147L.146 4.567a.5.5 0 0 0 0 .706l2.571 2.579a.5.5 0 0 0 .708 0l1.286-1.29a.5.5 0 0 0 .146-.353V5.57l8.387 8.873A.5.5 0 0 0 14 14.5l1.5-1.5a.5.5 0 0 0 .017-.689l-9.129-8.63c.747-.456 1.772-.839 3.112-.839a.5.5 0 0 0 .472-.334z"/>
                        </svg>
                        <samp>SOFTWARE PROJECTS</samp>
                    </button>

                    {/* <button onClick={() => workExperienceClicked ? setWorkExperienceClicked(false) : setWorkExperienceClicked(true)} className="section">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vw" height="4vh" fill="currentColor" className="bi bi-person-workspace" viewBox="0 0 16 16">
                            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                        </svg>
                        <samp>WORK EXPERIENCE</samp>
                    </button> */}
{/* 
                    <button onClick={() => educationClicked ? setEducationClicked(false) : (setProjectsClicked(false), setEducationClicked(true), setContactMeClicked(false), setWorkExperienceClicked(false))} className="section">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="4vw" height="4vh" fill="currentColor">
                            <path d="M7.693 1.066a.747.747 0 0 1 .614 0l7.25 3.25a.75.75 0 0 1 0 1.368L13 6.831v2.794c0 1.024-.81 1.749-1.66 2.173-.893.447-2.075.702-3.34.702-.278 0-.55-.012-.816-.036a.75.75 0 0 1 .133-1.494c.22.02.45.03.683.03 1.082 0 2.025-.221 2.67-.543.69-.345.83-.682.83-.832V7.503L8.307 8.934a.747.747 0 0 1-.614 0L4 7.28v1.663c.296.105.575.275.812.512.438.438.688 1.059.688 1.796v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3c0-.737.25-1.358.688-1.796.237-.237.516-.407.812-.512V6.606L.443 5.684a.75.75 0 0 1 0-1.368ZM2.583 5 8 7.428 13.416 5 8 2.572ZM2.5 11.25v2.25H4v-2.25c0-.388-.125-.611-.25-.735a.697.697 0 0 0-.5-.203.707.707 0 0 0-.5.203c-.125.124-.25.347-.25.735Z">
                            </path>
                        </svg>
                        <samp>EDUCATION</samp>
                    </button> */}

                    <button onClick={() => contactMeClicked ? setContactMeClicked(false) : (setProjectsClicked(false), setEducationClicked(false), setContactMeClicked(true), setWorkExperienceClicked(false))} className="section">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4vw" height="4vh" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                        </svg>
                        <samp>CONTACT ME</samp>
                    </button>

            </section>
        </Container>
        {projectsClicked ? <Projects /> : '' }
        {workExperienceClicked ? <WorkExperience /> : '' }
        {educationClicked ? <Education /> : '' }
        {contactMeClicked ? <ContactMe /> : '' }

    </>

    )
}
export default NavBox
