import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import React from 'react'
import MyFont from './Creme PastryDemo_Regular.json'

class Background extends React.Component {
    constructor(props) {
        super(props)
        this.sceneRef = React.createRef()

    }
    componentDidMount() {
        // Canvas
        const canvas = this.sceneRef.current
        const gl = canvas.getContext('webgl')
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            context: gl
        })

        // Scene
        const scene = new THREE.Scene()


        /**
         * Textures
         */


        //FONTS

        var fontLoader = new FontLoader()
        var font = fontLoader.parse(MyFont)
        const textgroup = new THREE.Group()
                        /////////        /////////        /////////        /////////        /////////        /////////
        const textGeometry = new TextGeometry
        (
            'amro salha',
            {
                font: font,
                size: 0.5,
                height: 0.8,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )

        textGeometry.center()

        const textMaterial = new THREE.MeshNormalMaterial()
        const text = new THREE.Mesh(textGeometry, textMaterial)
        textgroup.add(text)


        const text2Geometry = new TextGeometry
        (
            'Full Stack Software Engineer',
            {
                font: font,
                size: 0.2,
                height: 0.4,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )

        text2Geometry.center()
        text2Geometry.translate(0, -.559, 0)

        const material2 = new THREE.MeshNormalMaterial()
        const text2 = new THREE.Mesh(text2Geometry, material2)
        textgroup.add(text2)



        scene.add(textgroup)
        ///////////////////////////////////        /////////        /////////        /////////        /////////        /////////        /////////        /////////

        const material = new THREE.MeshNormalMaterial()

        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        const CylinderGeometry = new THREE.CylinderGeometry( 0.3, 0.2, 1, 32 )

        var group = new THREE.Group()

        for(let i = 0; i < 75; i++)
        {
            const donut = new THREE.Mesh(donutGeometry,material)
            const tknot = new THREE.Mesh(CylinderGeometry, material)
            donut.position.set((Math.random() - .5) * 9, (Math.random() - .5) * 9, (Math.random() - .5) * 9)
            tknot.position.set((Math.random() - .5) * 9, (Math.random() - .5) * 9, (Math.random() - .5) * 9)

            const scale = Math.random() * .8
            donut.scale.set(scale,scale,scale)
            tknot.scale.set(scale,scale,scale,scale)

            donut.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, 1)
            tknot.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, 1)

            group.add(donut)
            group.add(tknot)
        }

        scene.add(group)

        //////////////////////////

        /**
         * Sizes
         */
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        window.addEventListener('resize', () =>
        {
            // Update sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()

            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })

        //
        // Cursor
        const cursor = {
            x: 0,
            y: 0
        }

        window.addEventListener('mousemove', (event) =>
        {
            cursor.x = event.clientX / sizes.width - 0.5
            cursor.y = event.clientY / sizes.height - 0.5
        })

        /**
         * Camera
         */
        // Base camera
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
        scene.add(camera)

        /**
         * Renderer
         */
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        /**
         * Animate
         */
        const clock = new THREE.Clock()

        var rotation = 0;
        var rotation2 = 0;

        const tick = () =>
        {
            const elapsedTime = clock.getElapsedTime()

            rotation = (Math.sin(elapsedTime/4 * (Math.PI)));
            rotation2 = (Math.cos(elapsedTime/6 * (Math.PI)));
            group.rotation.y = rotation;
            group.rotation.x = rotation2

            textgroup.rotation.z = rotation2
            textgroup.rotation.x = rotation


            // Update camera
            camera.position.x = - (Math.sin(cursor.x * (Math.PI -.5)) * 2)
            camera.position.z = (Math.cos(cursor.x * (Math.PI -.5)) * 10)
            camera.position.y = cursor.y * 10
            camera.lookAt(new THREE.Vector3())

            // Render
            renderer.render(scene, camera)

            // Call tick again on the next frame
            window.requestAnimationFrame(tick)
        }

        tick()

    }

    render() {
        return <canvas className='webgl' ref={this.sceneRef} id='canvas'/>
    }
}

export default Background;
