import './style.css';
import Background from './components/Background';
import NavBox from './components/NavBox/NavBox';
// import {BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className='App'>
    <Background />
    <NavBox />
    {/* <BrowserRouter>
    <Routes>
      <Route path='/projects' element={<ContactMe />}></Route>
    </Routes>
    </BrowserRouter> */}
    </div>
  );
}

export default App;
