import './WorkExperience.css'
import { Container } from 'react-bootstrap'


const WorkExperience = () => {

    return (
        <Container className='WorkExperience-container'>
            <h1><samp>Work Experience</samp></h1>
            <section className='WorkExperience'>
                <div></div>
                <div></div>
                <div></div>
            </section>
        </Container>
    )
}
export default WorkExperience
