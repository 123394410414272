import './Education.css'
import { Container } from 'react-bootstrap'


const Education = () => {

    return (
        <Container className='Education-container'>
            <h1><samp>Education</samp></h1>
            <section className='Education'>
                <div></div>
                <div></div>
                <div></div>
            </section>
        </Container>
    )
}
export default Education
