import * as THREE from 'three'
import React from 'react'
import { TextureLoader } from 'three'
import TeachMeSS from './TeachMeSS.png'


class TeachMe extends React.Component {
    constructor(props) {
        super(props)
        this.sceneRef = React.createRef()
        this.renderer = null
        this.scene = null
        this.camera = null
    }
    componentDidMount() {
        // Canvas
        const canvas = this.sceneRef.current
        const gl = canvas.getContext('webgl')
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            context: gl
        })

        // Scene
        this.scene = new THREE.Scene()

        /**
         * Textures
         */

        const projectTexture = new THREE.TextureLoader()
        projectTexture.load(TeachMeSS, (texture) => {
            const material = new THREE.MeshStandardMaterial({map: texture})
            const planeGeometry = new THREE.PlaneGeometry(5,3)
            const plane = new THREE.Mesh(planeGeometry,material)
            this.scene.add(plane)
            texture.needsUpdate = true;

        })

        /**
         * Lights
         */

        const pointLight = new THREE.PointLight('#ffffff' , 7,4)
        pointLight.position.set(0,1,1)
        this.scene.add(pointLight)

        /**
         * Sizes
         */
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        window.addEventListener('resize', () =>
        {
            // Update sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            // Update camera
            this.camera.aspect = sizes.width / sizes.height
            this.camera.updateProjectionMatrix()

            // Update renderer
            if (this.renderer) {
                this.renderer.setSize(sizes.width, sizes.height)
                this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            }
        })

        /**
         * Camera
         */
        // Base camera
        this.camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
        this.camera.position.x = 0
        this.camera.position.z = 2.5
        this.camera.position.y = 0
        this.camera.lookAt(this.scene.position)
        this.scene.add(this.camera)

        // Add event listener for mousemove
        window.addEventListener('mousemove', this.handleMouseMove)

        /**
         * Renderer
         */
        this.renderer.setSize(sizes.width, sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        /**
         * Animate
         */
        this.tick()

    }
    componentWillUnmount() {
        // Dispose of WebGLRenderer
        if (this.renderer) {
            this.renderer.dispose()
            this.renderer.domElement = null
            this.renderer = null
        }
        if (this.material && this.material.map) {
            this.material.map.dispose();
            this.material.map = null;
          }
        // Remove event listeners
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('mousemove', this.handleMouseMove)

        // Cancel animation
        cancelAnimationFrame(this.animationFrameId)
    }
    handleMouseMove = (event) => {
        // Update cursor position
        const cursor = {
            x: event.clientX / window.innerWidth - 0.5,
            y: event.clientY / window.innerHeight - 0.5
        }

        // Update camera
        this.camera.position.x = -(Math.sin(cursor.x / 10 * (Math.PI - 0.5)) * 2)
        this.camera.position.z = Math.cos(cursor.x / 10 * (Math.PI - 0.5)) * 2.5
        this.camera.position.y = cursor.y
        this.camera.lookAt(this.scene.position)
    }
    tick = () => {

        if (this.renderer) {
            this.renderer.render(this.scene, this.camera)
        }

        // Call tick again on the next frame
        window.requestAnimationFrame(this.tick)
    }

    render() {
        return <canvas className='webgl project' ref={this.sceneRef} id='canvas'/>
    }
}

export default TeachMe;
